import React, { lazy } from "react";
import { YLoader } from "@ywfe/ywd";

// const token = localStorage.getItem('token');

const AccountManage = lazy(() => import("../pages/accountManage"));
const AnchorManage = lazy(() => import("../pages/anchorManage/anchorManage"));
const PerformanceManage = lazy(
  () => import("../pages/businessManage/performanceManage")
);
const BDManage = lazy(() => import("../pages/businessManage/BDManage"));
const AuditAnchor = lazy(() => import("../pages/anchorManage/auditAnchor"));

const SampleSetting = lazy(() => import("../pages/sampleManage/sampleSetting"));
const SampleManage = lazy(() => import("../pages/sampleManage/sampleManage"));
const ReturnManage = lazy(() => import("../pages/sampleManage/returnManage"));
const BannerManage = lazy(() => import("../pages/bannerManage"));
const DistributionLibrary = lazy(
  () => import("../pages/goodsManage/distributionLibrary")
);
const PlanManage = lazy(() => import("../pages/planManage/index"));
const SearchRecord = lazy(() => import("../pages/searchRecord/index"));
const BrandManagement = lazy(() => import("../pages/brandManagement/index"));
const DistributionSettlement = lazy(
  () => import("../pages/financialManagement/distributionSettlement")
);
const LiveRoomManage = lazy(() => import("../pages/liveRoomManage/index"));
const DistributeCPSorder = lazy(
  () => import("../pages/orderManagement/distributeCPSorder")
);
const DistributeOneItemOrder = lazy(
  () => import("../pages/orderManagement/distributeOneItemOrder")
);
const FreeShopOrder = lazy(
  () => import("../pages/orderManagement/freeShopOrder")
);
const SampleRoomManage = lazy(() => import("../pages/sampleRoomManage/index"));
const ReceiveSend = lazy(() => import("../pages/sampleManage/receiveSend"));
const UnReturnManage = lazy(
  () => import("../pages/sampleManage/unreturnManage")
);
const ScheduleManage = lazy(
  () => import("../pages/anchorManage/scheduleManage")
);
const LiveSchedule = lazy(() => import("../pages/liveSchedule"));

export const routerConfig = {
  // 账号管理
  // "accountManage": {
  //   icon: "iconzhanghaoguanli",
  // },
  accountManage: {
    component: (props: { state: { permissions: string[] } }) => {
      return (
        <YLoader>
          <AccountManage permissions={props.state.permissions} />
        </YLoader>
      );
    },
  },
  goodsManage: {},
  // 商品管理 -- 分销商品库
  "goodsManage/distributionLibrary": {
    component: () => (
      <YLoader>
        <DistributionLibrary />
      </YLoader>
    ),
  },

  // 主播管理
  anchorManage: {},
  "anchorManage/anchorManage": {
    component: (props: { state: { permissions: string[] } }) => (
      <YLoader>
        <AnchorManage permissions={props.state.permissions} />
      </YLoader>
    ),
  },
  // 待审核
  "anchorManage/auditAnchor": {
    component: () => (
      <YLoader>
        <AuditAnchor />
      </YLoader>
    ),
  },
  // 排期管理
  "anchorManage/scheduleManage": {
    component: (props: { state: { permissions: string[] } }) => (
      <YLoader>
        <ScheduleManage permissions={props.state.permissions} />
      </YLoader>
    ),
  },
  businessManage: {},
  "businessManage/BDManage": {
    component: (props: { state: { permissions: string[] } }) => (
      <YLoader>
        <BDManage permissions={props.state.permissions} />
      </YLoader>
    ),
  },
  "businessManage/performanceManage": {
    component: () => (
      <YLoader>
        <PerformanceManage />
      </YLoader>
    ),
  },
  // banner管理
  bannerManage: {
    // icon: "iconzhanghaoguanli",
    component: () => (
      <YLoader>
        <BannerManage />
      </YLoader>
    ),
  },
  // 样品管理
  sampleManage: {
    // icon: "iconzhanghaoguanli",
  },
  // 样品设置
  "sampleManage/sampleSetting": {
    component: () => (
      <YLoader>
        <SampleSetting />
      </YLoader>
    ),
  },
  // 样品管理
  "sampleManage/sampleMana": {
    component: () => (
      <YLoader>
        <SampleManage />
      </YLoader>
    ),
  },
  // 寄回管理
  "sampleManage/returnManage": {
    component: () => (
      <YLoader>
        <ReturnManage />
      </YLoader>
    ),
  },
  // 样品超时未归还
  "sampleManage/unreturnManage": {
    component: () => (
      <YLoader>
        <UnReturnManage />
      </YLoader>
    ),
  },
  // 收发样品
  "sampleManage/receiveSend": {
    component: () => (
      <YLoader>
        <ReceiveSend />
      </YLoader>
    ),
  },

  // 计划管理
  planManage: {
    component: () => (
      <YLoader>
        <PlanManage />
      </YLoader>
    ),
  },

  liveSchedule: {},
  "liveSchedule/liveSchedulePlan": {
    component: () => (
      <YLoader>
        <LiveSchedule />
      </YLoader>
    ),
  },

  // 搜索记录
  searchRecord: {
    component: () => (
      <YLoader>
        <SearchRecord />
      </YLoader>
    ),
  },

  // 品牌方管理
  brandManagement: {
    component: (props: { state: { permissions: string[] } }) => (
      <YLoader>
        <BrandManagement permissions={props.state.permissions} />
      </YLoader>
    ),
  },

  // 财务管理
  financialManagement: {},

  // 财务管理 - 分销结算
  "financialManagement/distributionSettlement": {
    component: () => (
      <YLoader>
        <DistributionSettlement />
      </YLoader>
    ),
  },

  // 订单管理
  orderManagement: {},

  // 订单管理--分销CPS订单
  "orderManagement/distributeCPSorder": {
    component: () => (
      <YLoader>
        <DistributeCPSorder />
      </YLoader>
    ),
  },

  // 订单管理--分销一件代发订单
  "orderManagement/distributeOneItemOrder": {
    component: () => (
      <YLoader>
        <DistributeOneItemOrder />
      </YLoader>
    ),
  },

  // 订单管理--分销CPS订单
  "orderManagement/freeShopOrder": {
    component: () => (
      <YLoader>
        <FreeShopOrder />
      </YLoader>
    ),
  },

  // 直播间管理
  liveRoomManage: {},

  // 直播间管理 -- 直播间列表
  "liveRoomManage/liveRoomList": {
    component: () => (
      <YLoader>
        <LiveRoomManage />
      </YLoader>
    ),
  },

  // 样品间管理
  sampleRoomManage: {
    component: () => (
      <YLoader>
        <SampleRoomManage />
      </YLoader>
    ),
  },
};
