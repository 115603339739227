import React from "react";
import { YApplication } from "@ywfe/ywd";
import * as YWD from "@ywfe/ywd";
import { routerConfig } from "./router";

console.log("YWD", YWD);

const App: React.FC = () => (
  <YApplication
    config={{
      routerConfig,
      projectId: "PRODISTRIBUTE",
      primaryDefaultMenu: "/anchorManage/anchorManage",
    }}
  />
  // <YApplication config={{ routers: routerConfig, projectId: "PRODISTRIBUTE" }} />
);

export default App;
