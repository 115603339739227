import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import App from './App';
import './styles/common.less'
import { getQueryString } from './utils';
moment.locale('zh-cn');

const root = document.getElementById('app');

const token = getQueryString('token') || '';
if (token && typeof window !== 'undefined') {
  localStorage.setItem('token', token)
}
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <HashRouter>
      <App/>
    </HashRouter>
  </ConfigProvider>,
  root,
);
