import moment, { Moment } from "moment";
import Big from "big.js";
import { configs } from "../generated/config";

/**
 * 时间戳转换
 */
export const formatTime = (
  date: string | Moment,
  format = "YYYY-MM-DD HH:mm:ss"
): string => {
  if (!date) return "";
  return moment(date).format(format);
};

/**
 * 从头开始，最大截取多少个字符串
 * @param str
 * @param len
 */
export const maxSubstrWord = (str: string, len = 5): string => {
  if (typeof str === "string" && str.length > len) {
    return `${str.substr(0, len)}...`;
  }
  return str;
};

/**
 * 价格分到元转化
 * */
export const formatPrice = (price: string | number, num: number = 2) => {
  return price && !isNaN(Number(price))
    ? Big(Number(price)).div(Big(100)).toFixed(num)
    : 0;
};

export const addQueries = (params: {
  [x: string]: any;
  token?: string | null;
}) => {
  const arr: string[] = [];
  Object.keys(params).forEach((key) => {
    if (
      params[key] !== "" &&
      params[key] !== null &&
      params[key] !== undefined
    ) {
      arr.push(`${key}=${params[key]}`);
    }
  });
  return arr.join("&");
};

// 导出excel
export const exportExcel = (url: string, params = {}, noPre?: boolean) => {
  let protectId = '';
  let env = 'prod';
  let data = params;
  if(typeof window !== 'undefined'){
    protectId = window.localStorage.getItem("projectId") || '';
    env = window.localStorage.getItem("env") || "prod";
    data = Object.assign({}, params, {
      token: window.localStorage.getItem("token"),
    });
  }
  if (typeof protectId === "string" && typeof env === "string") {
    const downloadUrl = `${
      noPre ? "" : configs[protectId][env].API_BASE_URL
    }${url}?${addQueries(data)}`;
    console.log(`导出excel：${downloadUrl}`);
    const id = `alink-${new Date().getTime()}`;
    const aDom = document.createElement("a");
    aDom.setAttribute("href", downloadUrl);
    aDom.setAttribute("target", "_blank");
    aDom.setAttribute("id", id);
    if (!document.getElementById(id)) {
      document.body.appendChild(aDom);
    }
    // 模拟点击
    aDom.click();
    // 删除这个假dom
    setTimeout(() => {
      document.body.removeChild(aDom);
    }, 0);
  }
};

export function GetKeyValueToOptions(data = {}) {
  const _data = JSON.parse(JSON.stringify(data));
  return Object.keys(_data).map((key) => ({
    value: key,
    label: _data[key],
  }));
}

/**
 * 获取地址栏参数
 * @author zimu
 * @export
 * @param {*} name
 * @returns
 */
export function getQueryString(name: string) {
  const url = window.location.href;
  const reg = new RegExp(`(\\?|&)${name}=([^&]*)(&|$)`, "i");
  const r = url.match(reg);

  if (r !== null) {
    return unescape(r[2]);
  }
  return null;
}
